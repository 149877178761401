export const apiCallList =
{
    data ()
    {
        return {
            error: false,
            data: null,
            items: [],
            loading: false,
            server_error: null
            // currentNewsPage: 1,
            // maxPages: 0,
            // show_all : '',
            // search : null
        }
    },
    created ()
    {
        this.fetch ();
    },
    methods:
    {
        fetch ()
        {
            this.$store.dispatch ('isLoadingOn');
            this.$axios.get (`/${this.endpoint}`)
            // this.$axios.get (`/${this.endpoint}?items=12&page=${this.currentNewsPage}${this.show_all}${search}`)
            .then ((response) =>
            {
                this.$store.dispatch ('isLoadingOff');
                this.data = response.data;
                // this.maxPages = response.data.pagination.totalPages;
                // if (this.maxPages < this.currentNewsPage) this.currentNewsPage = 1;
                this.loadItems ();
            })
            .catch (() =>
            {
                this.$store.dispatch ('isLoadingOff');
                this.data = [];
                this.loadItems ();
            })
        }
        // changePage (pageNum)
        // {
        //     this.page.current = pageNum;
        //     this.fetch (this.page.current);
        // },
        // loadPage (page)
        // {
        //     this.currentNewsPage = page;
        //     this.fetch ();
        // },
        // updateparent ()
        // {
        //     this.fetch ();
        // }
    }
}

export const apiCallSingle =
{
    data ()
    {
        return {
            type: this.$t ('Add'),
            error: false,
            loading: false,
            server_error: null
        }
    },
    async created ()
    {
        if (this.id)
        {
            this.type =  this.$t ('Edit');
            await this.fetch ();
        }
    },
    methods:
    {
        async fetch ()
        {
            this.$store.dispatch ('isLoadingOn');
            let self = this;
            return new Promise (function (resolve)
            {
                self.$axios.get (`/${self.endpoint}/${self.id}`)
                .then (
                    response =>
                    {
                        self.data = response.data;
                        self.loadData ();
                        self.$store.dispatch ('isLoadingOff');
                        resolve ();
                    }
                )
                .catch (
                    (error) =>
                    {
                        self.error = error
                        self.$store.dispatch ('isLoadingOff');
                        resolve ();
                    }
                )
            });
        },
        submit ()
        {
            this.server_error = '';
            this.error = false;
            this.loading = true;
            this.$v.$touch ();
            if (!this.$v.$invalid) this.onSave ();
            else
            {
                let error_list = "<li>Complete los campos del formulario.</li>";
                this.setError (error_list);
            }  
        },
        onSave ()
        {            
            let action;
            const properties = this.getProperties ();
            this.$store.dispatch ('isLoadingOn');
            if (this.id) action = this.$axios.put (`/${this.endpoint}/${this.id}`, properties);
            else action = this.$axios.post (`/${this.endpoint}`, properties);
            action
            .then (() =>
            {
                this.$store.dispatch ('isLoadingOff');
                // let data = response.data.data;
                this.$dialog
                .alert (
                {
                    title: this.title,
                    body: this.$t ("save_complete")
                })
                .then (() =>
                {
                    this.$router.push ('/' + this.section)
                });              
            })
            .catch ((err) =>
            {  
                this.$store.dispatch ('isLoadingOff');         
                let error_list = '';
                if (err.response.data.result.validationErrors.messages && Object.keys (err.response.data.result.validationErrors.messages).length > 0)
                {
                    let error = err.response.data.result.validationErrors.messages;
                    Object.values (error).forEach (
                        item =>
                        {
                            error_list += '<li>' + item + '</li>';
                        }
                    )
                }
                else if (err.response.data.result.Errors && err.response.data.result.Errors.length > 0) error_list = err.response.data.result.Errors.message;
                else if (err.response.data.result.otherErrors.message && err.response.data.result.otherErrors.message.length > 0)
                {
                    let error = err.response.data.result.otherErrors.message;
                    Object.values (error).forEach (
                        item =>
                        {
                            error_list += '<li>' + item + '</li>';
                        }
                    )
                }
                else
                {
                    if (this.section == "admin/templates") this.templateFields (err.response);
                    else error_list = err.response.data.message;
                }
                this.setError (error_list);
            })
        },
        toggleActive (toggle)
        {
            this.properties.active = toggle.value;
        },
        setError (error_list)
        {
            this.loading = false;
            this.buton_text = 'Enviar'
            this.error = true;
            this.server_error = error_list;
        }
    }
}