<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes=" table-responsive">
            <template slot="header">
             <div class="row">
                <div class="col-8 my-4 ">
                  <h4 class="card-title ml-4">{{$t('solicitud_documentos')}}</h4>                  
                </div> 
                  <div class="col-4 d-flex justify-content-end align-items-center">
                      <div class="mr-2">
                      <router-link to="plantilla_solicitud" class="btn bg-dark text-white font-weight-bold py-2 ">
                          <i class="fas fa-plus d-none d-sm-inline-block d-md-inline-block "></i>
                          <span class="font-weight-bold px-2">
                              {{$t("Agregar")}}
                          </span> 
                      </router-link>
                      </div>
                </div>                    
             </div>
            </template>
            <l-table class="pl-4 table-hover table-striped"
                     :columns="table.columns"
                     :section="section"
                     @deleteEvent="onDeleteEvent"
                     :data="tableData">
            </l-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiCallList } from '../../mixins'

  const tableColumns = ['id', 'name','edit','delete_event']
  export default {
    mixins: [apiCallList],
    components: {
     
    },
    data () {
      return {
        table: {
          columns: [...tableColumns],
        },
        section : "plantilla_solicitud",
        tableData : [],
        endpoint : "template_request_documentation",
      }
    },
    methods : {
        loadItems(){           
            this.tableData = this.data;
            setTimeout(() => {
              window.$(".table").dataTable({
                pageLength: 25,
                language : {
                  url : '//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json'
                }
              });
            },100);
        },
        onDeleteEvent(item){
          this.$dialog.confirm({
              title: this.$t("deleteTemplate"),
              body: this.$t("deleteTemplateText"),
              okText: this.$t("Aceptar"),
              cancelText: this.$t("Cancelar"),
          })
          .then(() => {
              this.$store.dispatch('isLoadingOn');           
              this.$axios.delete("/template_request_documentation/"+item.id)
                .then(() => {
                  this.fetch();
                  this.$store.dispatch('isLoadingOff');           
                })
            })
        }
    }
  }
</script>
<style>
</style>
